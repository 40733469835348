import {
  FormLinks,
  SelectField,
  SubmitButton,
  TextField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { Brand, Form, Species } from "src/models";

export const MARKETS = { aus: "Australia", gbr: "United Kingdom" };
const TERPENES = [
  "alpha-pinene",
  "beta-pinene",
  "bisabolol",
  "caryophyllene",
  "mycrene",
  "humulene",
  "limonene",
  "linalool",
  "nerolidol",
  "ocimene",
  "terpinolene",
];

const FormSchema = z.object({
  brand: z.string(),
  form: z.string(),
  markets: z.array(z.string()).min(1),
  nameInternal: z.string().trim().min(1),
  nameaus: z.string(),
  namegbr: z.string(),
  species: z.string(),
  strain: z.string(),
  terpenes: z.array(z.string()),
});
export type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  defaultValues: FormType;
  disabled?: boolean;
  label: string;
  onSubmit: (data: ValidatedType) => Promise<void>;
}

const FormulaForm = ({ defaultValues, disabled, label, onSubmit }: IProps) => {
  const methods = useForm<FormType>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => methods.reset(defaultValues), [defaultValues]);

  const markets = methods.watch("markets");

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CardContent>
          <TextField
            autoComplete="off"
            fullWidth
            label="Internal name"
            name="nameInternal"
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Markets"
            multiple
            name="markets"
            options={Object.entries(MARKETS).map(([value, label]) => ({
              label,
              value,
            }))}
            required
          />
          {markets.map((market) => (
            <TextField
              key={market}
              autoComplete="off"
              fullWidth
              label={`${market} name`}
              name={`name${market}`}
            />
          ))}
          <SelectField
            autoComplete="off"
            fullWidth
            label="Brand"
            name="brand"
            options={Object.values(Brand).map((value) => ({ value }))}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Form"
            name="form"
            options={Object.values(Form).map((value) => ({ value }))}
            required
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Species"
            name="species"
            options={Object.values(Species).map((value) => ({ value }))}
            required
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Strain"
            name="strain"
          />
          <SelectField
            autoComplete="off"
            fullWidth
            label="Terpenes"
            multiple
            name="terpenes"
            options={TERPENES.map((value) => ({ value }))}
            required
          />
        </CardContent>
        <Divider />
        <CardActions>
          <SubmitButton disabled={disabled} label={label} />
          <FormLinks links={[{ label: "Back", to: "/formulas/" }]} />
        </CardActions>
      </form>
    </FormProvider>
  );
};

export default FormulaForm;
