import { Title, useHash } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Link as WLink } from "wouter";

import Details from "src/pages/Formula/Details";
import History from "src/pages/Formula/History";
import Markets from "src/pages/Formula/Markets";
import { useFormulaQuery } from "src/queries";

type TabState = "details" | "history" | "markets";

interface IProps {
  formulaId: string;
}

const Formula = ({ formulaId }: IProps) => {
  const { data: formula } = useFormulaQuery(formulaId);
  const [tab, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          disabled={formula === undefined}
          to={`/formulas/${formulaId}/edit/`}
        >
          Edit
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        breadcrumbs={[
          {
            to: "/formulas/",
            label: "Formulas",
          },
          { label: formula?.nameInternal ?? "" },
        ]}
        title={formula?.nameInternal ?? ""}
      />
      <TabContext value={tab}>
        <TabList
          aria-label="Formula menu"
          onChange={(_, value: string) => setTab(value as TabState)}
          sx={{ marginBottom: 2 }}
        >
          <Tab label="Details" value="details" />
          <Tab label="History" value="history" />
          <Tab label="Markets" value="markets" />
        </TabList>
        <TabPanel value={"details"}>
          <Details formula={formula} />
        </TabPanel>
        <TabPanel value={"history"}>
          <History formulaId={formulaId} />
        </TabPanel>
        <TabPanel value={"markets"}>
          <Markets formulaId={formulaId} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default Formula;
