import { z } from "zod";

import { Brand, Form, Species } from "src/models/formula";

const formulaHistorySchema = z.object({
  id: z.string().uuid(),
  approved: z.coerce.date().nullable(),
  approverEmail: z.string().nullable(),
  approverId: z.number().nullable(),
  brand: z.nativeEnum(Brand),
  form: z.nativeEnum(Form),
  formulaId: z.string(),
  markets: z.array(z.string()).min(1),
  marketNames: z.record(z.string(), z.string()),
  nameInternal: z.string(),
  qualityReference: z.string().nullable(),
  species: z.nativeEnum(Species),
  staffEmail: z.string(),
  staffId: z.number(),
  strain: z.string(),
  terpenes: z.array(z.string()),
  timestamp: z.coerce.date(),
});

export type FormulaHistory = z.infer<typeof formulaHistorySchema>;

export const newFormulaHistory = (data: unknown): FormulaHistory =>
  formulaHistorySchema.parse(data);
