import { Title, ToastContext } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext } from "react";
import { useLocation } from "wouter";

import FormulaForm, {
  MARKETS,
  ValidatedType,
} from "src/components/FormulaForm";
import { Brand, Form, Species } from "src/models";
import { useCreateFormulaMutation } from "src/queries";

const CreateFormula = () => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { mutateAsync: create } = useCreateFormulaMutation();

  const onSubmit = async (data: ValidatedType) => {
    try {
      const marketNames: Record<string, string> = {};
      Object.keys(MARKETS).map((market) => {
        if (data[`name${market}` as "nameaus"].trim() !== "") {
          marketNames[market] = data[`name${market}` as "nameaus"].trim();
        }
      });
      await create({
        brand: data.brand as Brand,
        form: data.form as Form,
        markets: data.markets,
        marketNames,
        nameInternal: data.nameInternal,
        species: data.species as Species,
        strain: data.strain,
        terpenes: data.terpenes,
      });
      addToast("Formula added", "success");
      setLocation("/formulas/");
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: "/formulas/",
            label: "Formulas",
          },
          { label: "Add formula" },
        ]}
        title="Add formula"
      />
      <Card>
        <FormulaForm
          defaultValues={{
            brand: Brand.CURALEAF,
            form: Form.FLOWER,
            markets: ["gbr"],
            nameInternal: "",
            nameaus: "",
            namegbr: "",
            species: Species.INDICA,
            strain: "",
            terpenes: [],
          }}
          label="Add"
          onSubmit={onSubmit}
        />
      </Card>
    </>
  );
};

export default CreateFormula;
