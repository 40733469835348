import {
  QueryKey,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios from "axios";

import { Brand, Form, Formula, Species, newFormula } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useFormulasQuery = () =>
  useQuery<Formula[]>({
    queryKey: ["formulas"],
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/formulas/", { signal });
      return response.data.formulas.map((data: unknown) => newFormula(data));
    },
  });

export const useFormulaQuery = (
  id: string | undefined,
  options?: Omit<
    UseQueryOptions<any, any, Formula, QueryKey>,
    "queryKey" | "queryFn"
  >,
) => {
  const queryClient = useQueryClient();
  return useQuery<Formula>({
    ...options,
    queryKey: ["formulas", id?.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/formulas/${id}/`, { signal });
      return newFormula(response.data);
    },
    enabled: id !== undefined,
    initialData: () =>
      id !== undefined
        ? queryClient
            .getQueryData<Formula[]>(["formulas"])
            ?.filter((formula) => formula.id === id)?.[0]
        : undefined,
  });
};

interface IFormulaData {
  brand: Brand;
  form: Form;
  markets: string[];
  marketNames: Record<string, string>;
  nameInternal: string;
  species: Species;
  strain: string;
  terpenes: string[];
}

export const useCreateFormulaMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IFormulaData) =>
      await axios.post("/v1/formulas/", data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["formulas"] });
    },
  });
};

export const useEditFormulaMutation = (formulaId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IFormulaData) =>
      await axios.put(`/v1/formulas/${formulaId}/`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["formulas"] });
    },
  });
};
