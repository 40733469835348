import {
  sortByKey,
  useHash,
  SkeletonRow,
  Title,
} from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState, ReactNode } from "react";
import { Link as WLink } from "wouter";

import { Formula } from "src/models";
import { useFormulasQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties = "brand" | "nameInternal" | "qualityReference";
type Tabs = "unapproved" | "approved";

const Formulas = () => {
  const [tab, setTab] = useHash<Tabs>("unapproved");
  const { data: formulas } = useFormulasQuery();
  const [order, setOrder] = useState<Direction>("asc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("nameInternal");

  const sortKey = (formula: Formula) => [formula[orderBy]];

  let rows: ReactNode | ReactNode[] = <SkeletonRow cols={2} />;

  if (formulas !== undefined && formulas.length > 0) {
    rows = formulas
      .filter(
        (formula) =>
          formula.markets.includes("gbr") &&
          (formula.approved === null) === (tab === "unapproved"),
      )
      .sort(sortByKey(sortKey, order))
      .map((formula) => (
        <TableRow key={formula.id}>
          <TableCell>
            <Link component={WLink} to={`/formulas/${formula.id}/`}>
              {formula.nameInternal}
            </Link>
          </TableCell>
          <TableCell>{formula.brand}</TableCell>
          <TableCell>{formula.qualityReference}</TableCell>
        </TableRow>
      ));
  } else if (formulas !== undefined) {
    rows = (
      <TableRow>
        <TableCell colSpan={2}>No formulas found</TableCell>
      </TableRow>
    );
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <Title title="Quality" />
      <Card>
        <TabContext value={tab}>
          <TabList
            onChange={(_, value: string) => setTab(value as Tabs)}
            sx={{ marginLeft: 3 }}
          >
            <Tab label="Unapproved" value="unapproved" />
            <Tab label="Approved" value="approved" />
          </TabList>
        </TabContext>
        <Divider />
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "nameInternal"}
                    direction={order}
                    onClick={onSortClick("nameInternal")}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "brand"}
                    direction={order}
                    onClick={onSortClick("brand")}
                  >
                    Brand
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "qualityReference"}
                    direction={order}
                    onClick={onSortClick("qualityReference")}
                  >
                    Quality Reference
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default Formulas;
