import { Value } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { Formula } from "src/models";

interface IProps {
  formula?: Formula;
}

const Details = ({ formula }: IProps) => (
  <Card>
    <CardContent>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Brand</TableCell>
            <TableCell>
              <Value text={formula?.brand} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Form</TableCell>
            <TableCell>
              <Value text={formula?.form} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Species</TableCell>
            <TableCell>
              <Value text={formula?.species} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Strain</TableCell>
            <TableCell>
              <Value text={formula?.strain} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Terpenes</TableCell>
            <TableCell>
              <Value text={formula?.terpenes.join(", ")} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardContent>
  </Card>
);

export default Details;
