import { z } from "zod";

export enum Brand {
  CURALEAF = "CURALEAF",
  FOUR_20 = "FOUR_20",
  FIND = "FIND",
  GRASSROOTS = "GRASSROOTS",
  HUALA = "HUALA",
}

export enum Form {
  CAPSULE = "CAPSULE",
  FLOWER = "FLOWER",
  OIL = "OIL",
  OINTMENT = "OINTMENT",
  PASTILLE = "PASTILLE",
  VAPE_CARTRIDGE = "VAPE_CARTRIDGE",
}

export enum Species {
  BALANCED = "BALANCED",
  INDICA = "INDICA",
  SATIVA = "SATIVA",
}

const formulaSchema = z.object({
  id: z.string().uuid(),
  approved: z.coerce.date().nullable(),
  approverEmail: z.string().nullable(),
  approverId: z.number().nullable(),
  brand: z.nativeEnum(Brand),
  form: z.nativeEnum(Form),
  historyId: z.string().uuid(),
  markets: z.array(z.string()).min(1),
  marketNames: z.record(z.string(), z.string()),
  nameInternal: z.string(),
  qualityReference: z.string().nullable(),
  species: z.nativeEnum(Species),
  strain: z.string(),
  terpenes: z.array(z.string()),
});

export type Formula = z.infer<typeof formulaSchema>;

export const newFormula = (data: unknown): Formula => formulaSchema.parse(data);
