import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { useFormulasQuery } from "src/queries";

const Dashboard = () => {
  const { data: formulas } = useFormulasQuery();

  const unapproved = formulas?.filter(
    (formula) => formula.approved === null,
  ).length;

  return (
    <>
      <Paper square={false} sx={{ padding: 2, width: 128 }} variant="outlined">
        <Typography align="right" gutterBottom variant="h3">
          {unapproved}
        </Typography>
        <Typography variant="body2">
          Unapproved formula{(unapproved ?? 0) > 1 ? "s" : ""}
        </Typography>
      </Paper>
    </>
  );
};

export default Dashboard;
