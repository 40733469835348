import { MenuLinkButton } from "@curaleaf-international/components";
import AddTaskIcon from "@mui/icons-material/AddTask";
import GroupsIcon from "@mui/icons-material/Groups";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import RequiresRoles from "src/components/RequiresRoles";
import { StaffRole } from "src/models";

const DRAWER_WIDTH = 240;

interface IProps {
  closeDrawer: () => void;
  drawerOpen: boolean;
}

const LeftDrawer = ({ closeDrawer, drawerOpen }: IProps) => {
  const { member } = useContext(AuthContext);
  const menu = (
    <>
      <Toolbar />
      <List>
        {member ? (
          <>
            <RequiresRoles
              roles={[
                StaffRole.DATA_MASTER,
                StaffRole.DEVELOPER,
                StaffRole.QUALITY,
                StaffRole.STAFF_MANAGER,
              ]}
            >
              <MenuLinkButton
                Icon={<VaccinesIcon />}
                label="Formulas"
                onClick={closeDrawer}
                to="/formulas/"
              />
            </RequiresRoles>
            <RequiresRoles roles={[StaffRole.QUALITY]}>
              <MenuLinkButton
                Icon={<AddTaskIcon />}
                label="Quality approvals"
                onClick={closeDrawer}
                to="/quality/"
              />
            </RequiresRoles>
            <RequiresRoles
              roles={[StaffRole.DEVELOPER, StaffRole.STAFF_MANAGER]}
            >
              <MenuLinkButton
                Icon={<GroupsIcon />}
                label="Staff"
                onClick={closeDrawer}
                to="/staff/"
              />
            </RequiresRoles>
          </>
        ) : null}
      </List>
    </>
  );

  return (
    <>
      <Drawer
        ModalProps={{
          keepMounted: true,
        }}
        onClose={closeDrawer}
        open={drawerOpen}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}
        variant="temporary"
      >
        {menu}
      </Drawer>
      <Drawer
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
          width: DRAWER_WIDTH,
          flexShrink: 0,
        }}
        open
        variant="permanent"
      >
        {menu}
      </Drawer>
    </>
  );
};

export default LeftDrawer;
