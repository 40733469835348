import { Title, ToastContext } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext } from "react";
import { useLocation } from "wouter";

import FormulaForm, {
  MARKETS,
  ValidatedType,
} from "src/components/FormulaForm";
import { Brand, Form, Species } from "src/models";
import { useEditFormulaMutation, useFormulaQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const EditFormula = ({ formulaId }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { data: formula } = useFormulaQuery(formulaId);
  const { mutateAsync: edit } = useEditFormulaMutation(formulaId);

  const onSubmit = async (data: ValidatedType) => {
    try {
      const marketNames: Record<string, string> = {};
      Object.keys(MARKETS).map((market) => {
        if (data[`name${market}` as "nameaus"].trim() !== "") {
          marketNames[market] = data[`name${market}` as "nameaus"].trim();
        }
      });
      await edit({
        brand: data.brand as Brand,
        form: data.form as Form,
        markets: data.markets,
        marketNames,
        nameInternal: data.nameInternal,
        species: data.species as Species,
        strain: data.strain,
        terpenes: data.terpenes,
      });
      addToast("Formula edited", "success");
      setLocation(`/formulas/${formulaId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: "/formulas/",
            label: "Formulas",
          },
          {
            to: `/formulas/${formulaId}/`,
            label: formula?.nameInternal ?? "",
          },
          { label: "Edit formula" },
        ]}
        title="Edit formula"
      />
      <Card>
        <FormulaForm
          defaultValues={{
            brand: formula?.brand ?? Brand.CURALEAF,
            form: formula?.form ?? Form.FLOWER,
            markets: formula?.markets ?? ["gbr"],
            nameInternal: formula?.nameInternal ?? "",
            nameaus: formula?.marketNames.aus ?? "",
            namegbr: formula?.marketNames.gbr ?? "",
            species: formula?.species ?? Species.INDICA,
            strain: formula?.strain ?? "",
            terpenes: formula?.terpenes ?? [],
          }}
          disabled={formula === undefined}
          label="Edit"
          onSubmit={onSubmit}
        />
      </Card>
    </>
  );
};

export default EditFormula;
