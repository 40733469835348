import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";

import { useFormulaQuery } from "src/queries";

interface IProps {
  formulaId: string;
}

const Markets = ({ formulaId }: IProps) => {
  const { data: formula } = useFormulaQuery(formulaId);

  return (
    <Grid container spacing={2}>
      {formula?.markets.map((market) => (
        <Grid key={market} item xs={12} sm={6} md={3}>
          <Card>
            <CardHeader
              title={`${market.toUpperCase()} - ${formula.marketNames[market] ?? formula.nameInternal}`}
            />
            <img
              alt="QR Code to PIL"
              width="100%"
              src={`/v1/formulas/${formulaId}/qr-code/${market}/?format=png`}
            />
            <CardActions>
              <Button
                component="a"
                download
                href={`/v1/formulas/${formulaId}/qr-code/${market}/?format=svg`}
                startIcon={<DownloadIcon />}
              >
                SVG
              </Button>
              <Button
                component="a"
                download
                href={`/v1/formulas/${formulaId}/qr-code/${market}/?format=png`}
                startIcon={<DownloadIcon />}
              >
                PNG
              </Button>
              <Button
                component="a"
                href={`/v1/formula-histories/${formula.historyId}/pil/${market}/`}
              >
                View PIL
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Markets;
